.LanguageContainer {
    position: relative;
    cursor: pointer;
    height: 100%;
    width: 40px;
    display: flex;
    align-items: center;
    color: white;
}

.LanguageContainer p {
    font-size: 1rem;
    margin: 0;
    text-align: right;
    margin-left: -1rem;
}

.Dropdown {
    position: absolute;
    display: none;    
    right: 0;
}

.displayLanguageWrapper {
    overflow: hidden; 
}

.Dropdown ul {  
    padding: 0;
    display: flex;
    flex-direction: column;
    background-color: rgb(0, 0, 0);
    align-items: flex-start;
    z-index: 8;
    margin-bottom: 0; 
    margin-top: 0;
width: fit-content;
}

.Dropdown li {
    list-style: none;
    font-size: 0.8rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
}

.Dropdown li:hover {
   background-color: rgba(255, 255, 255, 0.588);
}

.Dropdown li span {
    margin-right: 0.5rem; /* Add some margin to the right of the flag */
}

.Dropdown li p {
    margin: 0;
    flex-grow: 1;
    text-align: left;
}

.Dropdown.visible {
    display: flex;
    flex-direction: column;
    margin: 0 0.5rem;
    align-items: center;
    justify-content: flex-end;
    opacity: 1;
    visibility: visible;
    z-index: 5;
    overflow: hidden;
}

.displayLanguage {
    opacity: 0;
    visibility: hidden; 
    transition: opacity 10s ease-in 10s;
}

.displayLanguage.vis {
    opacity: 1;
    visibility: visible;
    margin-left: -7rem;
    display: flex;
    align-items: center;
}

.displayLanguage.vis span {
padding-right:30px
}
.displayLanguage.vis p {
    padding-left: 20px;
    }

    .downArrow {
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 10px solid white;
        margin-left: 1rem;
    }

 