.IntakeFormContainer {
    height: fit-content; /* Increased height */
    width: 40vw; /* Reduced width for a more compact form */
    background-color: #333; /* Dark background for dark theme */
    color: #fff; /* Light text color for contrast */
    border-radius: 10px;     
    padding: 20px;
    opacity: 0;
    box-shadow: 0 5px 15px rgba(0,0,0,0.6);
    margin: 3rem 0;
}

.IntakeFormContainer.visible {
    animation: slideIn 2s ease-in-out forwards;
    opacity: 1;
}

.FormTitle {
    margin: 1rem auto;
    text-align: center;
    font-weight: bolder;
    font-size: larger;
}

@keyframes slideIn {
    from {
        transform:scale(0.1 );
    }

    to {
        transform: scale( 1);
    }

    to {
        transform: translate(0%, 0%);
    }
}

form {
    width: 100%;
 
}

form label {
    font-size: 0.9rem;
   
    width: 100%;
    display: block;
    
}

form input[type="text"],
form input[type="email"],
form input[type="tel"],
form textarea {
    width: 100%;
    padding-top:5px;
    padding-bottom:5px;
    
    border: 1px solid #555;
    background-color: #222; /* Darker background for inputs */
    color: #fff; /* Light color for input text */
    border-radius: 4px;
}

form textarea {
    height: 80px; /* Adjusted for compactness */
}

form button {
    padding: 10px;
    background-color: #0066cc; /* Adjust for theme */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3;
    margin: 0 auto;
    display:block;
}

form button:hover {
    background-color: #0056b3;
}

/* Responsive design for smaller screens */
 
