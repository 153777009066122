h1{
    margin:0;
    padding:4rem;
}

.ContactContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('../Images/img2.jpeg');
    background-position:top;
    background-size: cover;
}