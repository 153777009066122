 

.AboutContainer{
   display:flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
       background-image: url('../Images/mnts.png');
    
    background-size: cover;
 height:100vh

}


.Conclusion {
    background-color: rgba(0, 0, 0, 0.765);
    color: white;
    padding:1rem;
    margin:2rem;
    display:none
}
 

.AboutContainer .Description{
    background-color: rgba(0, 0, 0, 0.781);
display:flex;
justify-content: center;
    border-radius: 20px;
    padding:2rem;
    border: rgb(255, 255, 255) 2px solid;
    width:40vw;
    color:white
 
}

.WhyChooseUsHead {
    margin: 1rem auto;
    text-align: center;
}

.WhyChooseUsDescription{
    margin: 0 1rem;
    display:flex;
    justify-content: space-around;
    align-items: flex-end;
   


}

.Reasons{
    background-color: rgba(0, 0, 0, 0.673);
    border-radius: 100px;
    height:10rem;
    padding: 1rem;;
    width: 10rem;
  color: white;
    text-align:center;
    display: flex;
    align-items: center;
    box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.372);;
}

.WhyChooseUsHead{
    font-size: 2rem;
}

