/* CSS file, e.g., App.css */
.circle-container {
  position: relative;
  height: 300px; /* Adjust based on your design */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  position: absolute;
  width: 15vw; /* Reduced size of circles */
  height: 15vw;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.871); /* Your color choice */
  transition: transform 1s ease; /* Animation effect */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.372);
  text-align: center;
  padding: 0.5rem;
}

/* Positions for desktop */
.circle-1 { transform: translateY(-12rem); } /* Top */
.circle-2 { transform: translateY(12rem); }  /* Bottom */
.circle-3 { transform: translate(-9rem, -5rem); } /* Top-Left */
.circle-4 { transform: translate(9rem, -5rem); }  /* Top-Right */
.circle-5 { transform: translate(-9rem, 5rem); } /* Bottom-Left */
.circle-6 { transform: translate(9rem, 5rem); }  /* Bottom-Right */

/* Positions for mobile */
@media (max-width: 600px) {
  .circle-1 { transform: translateY(-15rem); }
  .circle-2 { transform: translateY(15rem); }
  .circle-3 { transform: translate(-5rem, -5rem); }
  .circle-4 { transform: translate(5rem, -5rem); }
  .circle-5 { transform: translate(-5rem, 5rem); }
  .circle-6 { transform: translate(5rem, 5rem); }

  .circle {
      width: 8rem; /* Adjust the size of circles */
      height: 8rem;
  }
}

/* Initial state */
.circle.hidden {
  transform: translateX(0); /* All circles start from the center */
}
