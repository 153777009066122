.footer {
    width: 100%;
    background-color: #000;
    color: #eee;
    padding-top:2rem;
    padding-bottom:2rem;
    height:fit-content;
}
.SocialMedia a{
    color: white;
    padding: 0 0.5rem;
}
.footer .container {
    
    margin: 0 auto;
    text-align: center;
    height:fit-content;
}

.footer ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin:0;
    padding:0
}

.footer li {
    padding: 0.5rem;
    list-style: none;

}

.footer li a {
    color: #fff;
    text-decoration: none;
  
}

.footer li a:hover {
    padding-bottom: 12px;
    border-bottom: white solid 3px;
    cursor: pointer;
}

.bottom {
    text-align: center;
    padding-top: 3rem;
}

.line {
    margin-bottom: 1rem;
}

@media (max-width:800px) {
    .footer{max-height: fit-content;
    }
    .footer ul {
        display: flex;
      flex-direction: column;
    }
}