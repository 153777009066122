.TypeEffect{
    margin: 0 auto;
    text-align: center;
 color:rgb(255, 255, 255);
    font-family: 'Share Tech Mono', monospace;
    height: fit-content;
    font-size: 5rem;
    font-weight: 900;
    background-color: rgba(0, 0, 0, 0.184);
}

 

@media (max-width:900px) {

    .TypeEffect{
 
        font-size: 3rem;
  
    }
    

}

