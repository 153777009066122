 
  
  .logo:hover {
    cursor: pointer;
  }
  .header {
    position: fixed;
    height: 100px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: visible;
  }

  .Test {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    align-items: flex-end;
    height:100%;
    justify-content: flex-start;
    padding-top:1rem;
  }
  
  .header-bg {
    background-color: rgba(0, 0, 0, 0.836);
  }
 
  
  
  .header .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 0.5rem;
    margin: 0px;
    height: 100%;
   
  }
  
  .header .nav-menu a {
    color: #ffffff;
  }
  
  .header .navbar img {
    width: auto;
    height: 100px;
  }
  .NavAndLanguage{
    display: flex;
  }

  .header .nav-menu {
    display: flex;
    margin:0
  }


  
  .header .nav-item {
    padding: 0.7rem;
    cursor: pointer;
    list-style: none;
    font-size:large;
 
  }
  
  .header .nav-item a:hover {
    padding-bottom: 12px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.876);
  }
  
  .hamburger {
    display: none;
    margin-right:1rem;
  }
  
  .nav-item a {
    text-decoration: none; /* Removes the underline */
    text-transform: uppercase;
  }
 

.LanguageDiv {
  height:30%;
  display:flex;
  align-items: center;
  
  
}
 
  
  @media (max-width:800px) {

    
    .header {
         width: 100vw;
        
    }

    .header .navbar img {
        width: 100px;
        height: 100px;
      }
      
  
    .header .navbar {
        max-width: 100%;
    }
  
    .hamburger {
        display: block;
    }
  
    .nav-menu {
        padding:0;
        position: fixed;
        flex-direction: column;
        background-color: rgba(0, 0, 0, 0.887);
        width: 100%;
        height: 80vh;
        z-index: 3;
        text-align: center;
        transition: .3s;
        top: 50%; 
        left: 50%;
        opacity: 0;
    }
  
    .nav-menu.active {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
  
    .nav-item {
        margin: 1.5rem 0;
    }
  
 
  }