.ServicesContainer{
    display:grid;
    grid-template-columns: 1fr 1fr;
    overflow-x: hidden
}

.Title{
    text-align: center;
     
    font-size:1.3rem;
    color: rgb(240, 236, 236);
    font-weight:800;
}

.OuterServicesContainer{
    height:fit-content;
    background-image: url('../Images/city.png');
    padding: 5rem 0;
}

 

.Service{
    background-color: rgba(0, 0, 0, 0.912);
    border-radius: 30px;
    text-align: center;
    padding:1rem;
    margin:1rem;
    position:relative;
    box-shadow:10px 10px 3px rgba(0, 0, 0, 0.372);
    color: white;
    font-size: 1rem;

}
.Service.Right,
.Service.Left {
    opacity: 0; /* Start invisible */
     /* Start off-screen */
}

.Service.Right.visible {
    animation: slideleft 2s forwards;
    opacity: 1;
}

.Service.Left.visible {
    animation: slideright 2s forwards;
    opacity: 1;
}

  
    @keyframes slideleft {
        from {
            transform: translateX(-100%);
        }
        to {
          transform:translateX( 0%)
        }
      }
      
      @keyframes slideright {
        from {
            transform: translateX(100%);
        }
        to {
          transform:translateX( 0%)
        }
      }
      


@media(max-width:800px){
    .ServicesContainer{
        display:flex;
        flex-direction: column;
     
    }
}