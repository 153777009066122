.hero-container {
    position: relative;
    height: 100vh; /* Full height */
    width: 100%; /* Full width */
    overflow: hidden; /* Hide any overflow */
}

.hero-container video {
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1; /* Ensure video stays in the background */
}

/* Style for your content */
.hero-container h1 {
    position: relative;
    color: rgb(0, 0, 0);
    /* Additional styling */
}

.hero-container p {
    position: relative;
    color: rgb(0, 0, 0);
    /* Additional styling */
}

.hero-container .logo {
    width: 50vmin;
    height:auto;
    position: absolute;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust for the logo's own size */
    opacity: 0; /* Start with logo invisible */
    animation: floatIn 4s ease forwards 1s; /* Apply the animation */
    width: 100vmin;
}

@keyframes floatIn {
    from {
        transform: translateY(50px) translate(-50%, -50%);
        opacity: 0;
    }
    to {
        transform: translateY(0) translate(-50%, -50%);
        opacity: 1;
    }
}
    


 